import React from 'react';
import styled from 'styled-components';
import { primaryColor, spGigantic, spLarge, spMedium } from '../styleDefaults';

const ServiceCardDesign = styled.div`
  background-color: white;
  border-radius: ${spMedium};
  padding: ${spLarge};
  box-shadow: 2px 2px 2px #111111;
  display: flex;
  flex-direction: column;
  width: 22%;
  height: 70%;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: ${spGigantic};
  }

  p {
    font-size: 1.2rem;
  }
`;

const LinkToServiceButton = styled.div`
  background-color: ${primaryColor};
  color: white;
  padding: ${spLarge};
  border-radius: ${spMedium};
  position: absolute;
  top: 94%;
  width: calc(100% - (${spLarge} + ${spLarge}));
`;

export const ServiceCard = ({ title, description, link }) => {
  return (
    <ServiceCardDesign>
      <h1>{title}</h1>
      <p>{description}</p>
      <LinkToServiceButton>View More {'>'}</LinkToServiceButton>
    </ServiceCardDesign>
  );
};
