import React from 'react';
import Particles from 'react-particles-js';
import styled from 'styled-components';
import { lightBlueColor, primaryColor } from '../styleDefaults';

export const ParticleComponent = () => (
  <ParticlesWrapper>
    <Particles
      width="100%"
      height={window.outerHeight}
      canvasClassName="particlesCanvas"
      params={{
        fpsLimit: 60,
        interactivity: {
          detectsOn: 'window',
          events: {
            onHover: {
              enable: true,
              mode: 'repulse',
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 400,
              duration: 2,
              opacity: 0.8,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: primaryColor,
          },
          links: {
            color: lightBlueColor,
            distance: 200,
            enable: true,
            opacity: 0.5,
            width: 0.5,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: 'none',
            enable: true,
            outMode: 'bounce',
            random: false,
            speed: 4,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              value_area: 1200,
            },
            value: 100,
          },
          opacity: {
            value: 0.9,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }}
    />
  </ParticlesWrapper>
);

const ParticlesWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
