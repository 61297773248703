import React from 'react';
import services_img from '../assets/services_bg_blue.png';
import styled from 'styled-components';
import { spGigantic, spLarge } from '../styleDefaults';
import { ServiceCard } from './serviceCard';
import { AccentText } from '../commonStyled/general';

const ServicesWrap = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${services_img});
  background-size: cover;
  background-position: center;
  padding: ${spGigantic};
  display: grid;
  grid-template-rows: 20% 80%;

  #serviceTitle {
    text-align: center;
  }

  @media (max-width: 768px) {
    height: fit-content;
    grid-template-rows: 10% 90%;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  margin-top: ${spLarge};
  margin-bottom: ${spLarge};
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Services = () => {
  return (
    <ServicesWrap>
      <h1 id="serviceTitle">
        <AccentText>SERVICES</AccentText> OFFERED
      </h1>
      <CardsWrapper>
        <ServiceCard
          title="Auditing"
          description="a erat nam at lectus urna duis convallis convallis tellus id interdum velit rutrum quisque non tellus orci ac auctor augue mauris augue neque"
        />
        <ServiceCard
          title="Taxation"
          description="a erat nam at lectus urna duis convallis convallis tellus id interdum velit rutrum quisque non tellus orci ac auctor augue mauris augue neque"
        />
        <ServiceCard
          title="Laws"
          description="a erat nam at lectus urna duis convallis convallis tellus id interdum velit rutrum quisque non tellus orci ac auctor augue mauris augue neque"
        />
        <ServiceCard
          title="Advisory"
          description="a erat nam at lectus urna duis convallis convallis tellus id interdum velit rutrum quisque non tellus orci ac auctor augue mauris augue neque"
        />
      </CardsWrapper>
    </ServicesWrap>
  );
};
