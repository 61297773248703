import React from 'react';
import styled from 'styled-components';
import image from '../assets/landing_image_2.png';
import {
  lightBlueColor,
  secondaryColor,
  spGigantic,
  spLarge,
} from '../styleDefaults';

const LandingWrap = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  background-color: ${secondaryColor};

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  #first {
    display: flex;
    color: ${secondaryColor};
    justify-content: center;
    flex-direction: column;
    padding: ${spLarge};
    height: 100%;

    h1 {
      font-size: 2.5rem;
      -webkit-text-stroke: 1px black;
      font-weight: bold;
    }

    @media (max-width: 768px) {
      align-items: center;
      text-align: center;
      margin-top: ${spGigantic};
      margin-bottom: ${spGigantic};

      h1 {
        font-size: 2rem;
      }
    }
  }

  #second {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const AnotherButton = styled.button`
  border: 0;
  box-shadow: 2px 2px 2px #333333;
  border-radius: 40px;
  width: fit-content;
  padding: ${spLarge};
  background-color: ${lightBlueColor};
  font-size: 1.5rem;
  color: white;
`;



export const Landing = () => {
  return (
    <>
      <LandingWrap>
        <div id="first">
          <h1>GROW YOUR BUSINESS WITH US</h1>
          <div>
            <AnotherButton>Learn More</AnotherButton>
          </div>
        </div>
      </LandingWrap>
    </>
  );
};

  

/* 

contact form card design and styles

const LandingCardWrapper = styled.div`
  width: 80%;
`;

const LandingCard = styled.div`
  border-radius: ${spSmall};
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${spGigantic};
  opacity: 0.95;

  input,
  textarea {
    margin-bottom: ${spMedium};
    border: 0;
    border-bottom: 1px solid black;
    padding-top: ${spMedium};
    padding-bottom: ${spMedium};
    resize: none;
  }

  #lineOne {
    display: flex;
    width: 100% !important;
    justify-content: space-between;

    div {
      width: 49%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    #lineOne {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
`;

contact form submit button styles

const Button = styled.button`
  border: 1px solid black;
  box-shadow: 2px 2px gray;
  border-radius: 20px;
  width: fit-content;
  padding: ${spLarge};
  background-color: rgba(255, 255, 255, 0);
  margin-top: ${spGigantic};
`;

contact form markup

  <div id="second">
          <LandingCardWrapper>
            <LandingCard>
              <div id="lineOne">
                <div>
                  <label htmlFor="name">Name *</label>
                  <input type="text" id="name" />
                </div>
                <div>
                  <label htmlFor="company">Company Name(optional)</label>
                  <input type="text" id="company" />
                </div>
              </div>
              <label htmlFor="email">Email Address *</label>
              <input type="text" id="email" />
              <label htmlFor="phone">Phone Number *</label>
              <input type="text" id="phone" />
              <label htmlFor="phone">Message *</label>
              <textarea rows="5" />
              <CenterWrapper>
                <Button>Send us a message</Button>
              </CenterWrapper>
            </LandingCard>
          </LandingCardWrapper>
</div> */