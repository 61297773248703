import React from 'react'
import styled from 'styled-components'
import { lightBlueColor, primaryColor, secondaryColor, spLarge, spMedium, spSmall } from '../../styleDefaults'

export const Careers = () => {
    return (
        <CareersWrapper>
            <div id="filters">
                <input type="text" placeholder="Search by keyword..."/>
                <div className="filterSection">
                    <span className="filterHeader">Filter by experience</span>
                    <label>
                        <input type="radio" name="experience" value="entry" />
                        Entry
                    </label>
                    <label>
                        <input type="radio" name="experience" value="Intermediate" />
                        Intermediate
                    </label>
                    <label>
                        <input type="radio" name="experience" value="Mid-level" />
                        Mid-level
                    </label>
                    <label>
                        <input type="radio" name="experience" value="Senior/Executive" />
                        Senior/Executive
                    </label>
                    <label>
                        <input type="radio" name="experience" value="Other" />
                        Other
                    </label>
                </div>
                <div className="filterSection">
                    <span className="filterHeader">Filter by interest</span>
                    <label>
                        <input type="radio" name="interest" value="Accountancy" />
                        Accountancy
                    </label>
                    <label>
                        <input type="radio" name="interest" value="Management" />
                        Management
                    </label>
                    <label>
                        <input type="radio" name="interest" value="HR" />
                        HR
                    </label>
                    <label>
                        <input type="radio" name="interest" value="IT" />
                        IT
                    </label>
                    <label>
                        <input type="radio" name="interest" value="Auditing" />
                        Auditing
                    </label>
                </div>
            </div>
            <div id="content">
                <h1>Jobs Found(2)</h1>
                <CareerCard 
                    title="Human Resource Manager" 
                    experience="Minimum 3 years"
                    category="HR"
                    description="Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud 
                    exercitation ullamco laboris nisi ut aliquip 
                    ex ea commodo consequat."
                />
                <CareerCard 
                    title="Technical Director" 
                    experience="Minimum 6 years"
                    category="IT"
                    description="Lorem ipsum dolor sit amet, 
                    consectetur adipiscing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud 
                    exercitation ullamco laboris nisi ut aliquip 
                    ex ea commodo consequat."
                />
            </div>
        </CareersWrapper>
    )
}

const CareerCard = ({title, experience, category, description}) => {
    return (
        <CareerCardWrapper>
            <div id="header">
                <h1>{title}</h1>
                <p>Experience Required: {experience}</p>
                <p>Job Category: {category}</p>
            </div>
            <div id="description">
                <h3>Job Description</h3>
                <p>{description}</p>
            </div>
            <div id="actions">
                <button id="seeMore">See More</button>
                <button id="apply">Apply</button>
            </div>
        </CareerCardWrapper>
    )
}

const CareersWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding-top: 45px;
    border-bottom: 2px solid black;

    #filters {
        background-color: ${secondaryColor};
        border-right: 2px solid ${primaryColor};
        display: flex;
        flex-direction: column;
        grid-column: span 3;
        padding: 2rem;

        input[type=text] {
            padding: ${spMedium};
            border-radius: ${spSmall};
            margin-bottom: ${spLarge};
        }
        .filterHeader{
            color: ${lightBlueColor};
            margin-bottom: ${spLarge};
        }
        .filterSection{
            margin: ${spLarge} 0;
            display: flex;
            flex-direction: column;
            font-weight: bold !important;

            input[type=radio]{
                margin: 0;
                margin-right: ${spSmall};
            }

            label {
                margin-bottom: ${spMedium};
            }
        }
    }

    #content{
        grid-column: span 9;
        padding: 2rem;
        background-color: white;
    }

    @media (max-width: 900px){
        #filters{
            border-right: 0;
            border-bottom: 2px solid black;
        }
        #filters, #content{
            grid-column: span 12;
        }
    }
`

const CareerCardWrapper = styled.div`
    border: 1px solid black;
    padding: 2rem;
    margin-bottom: ${spLarge};


    p{
        color: gray;
        font-size: 0.8rem;
        margin-bottom: 0.2rem;
    }

    #header, #description{
        margin-bottom: 0.8rem;
    }

    #actions{
        display: flex;
        justify-content: flex-end;

        button{
            padding: ${spMedium};
            border: 0;
            margin: 0 ${spSmall};
        }

        #seeMore{
            border: 1px solid ${primaryColor};
            color: ${primaryColor};
            background-color: rgba(0,0,0,0);
        }

        #apply{
            color: white;
            background-color: ${primaryColor};
        }
    }

    @media(max-width: 900px){
        padding: 1rem;

        #actions{
            justify-content: center;
            margin-top: ${spLarge};

            button{
                 width: 50%
            }
        }
    }
`
