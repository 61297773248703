//colors
export const primaryColor = '#283556';
export const accentcolor = '#3f5f7f';
export const secondaryColor = '#f8f8ff';
export const lightBlueColor = '#526BDD';
//spacing sizes
export const spSmall = '0.5rem';
export const spMedium = '0.75rem';
export const spLarge = '1rem';
export const spGigantic = '3rem';
