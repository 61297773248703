import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  primaryColor,
  secondaryColor,
  spGigantic,
  spLarge,
} from '../styleDefaults';

const FooterDesign = styled.footer`
  background-color: ${primaryColor};
  color: ${secondaryColor};
  display: flex;
  justify-content: space-around;
  padding: ${spLarge};
  margin-top: ${spGigantic};

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .footerSection {
    display: flex;
    flex-direction: column;
    width: 20%;

    @media (max-width: 768px) {
      width: 100%;
    }
    a {
      color: ${secondaryColor};
      text-decoration: none;
    }

    p {
      margin: 0;
    }
  }
`;

const FooterBottom = styled.div`
  background-color: ${primaryColor};
  color: ${secondaryColor};
  padding: ${spGigantic};
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: ${spLarge};
    justify-content: flex-start;
  }
`;

export const Footer = () => {
  return (
    <>
      <FooterDesign>
        <div className="footerSection">
          <h3>Company</h3>
          <Link to="/about">About Us</Link>
          <Link to="/#team">Our Team</Link>
          <Link to="/service">Services</Link>
        </div>
        <div className="footerSection">
          <h3>Quick Links</h3>
          <Link to="/contact">Contact Us</Link>
          <Link to="/auditing">Auditing</Link>
          <Link to="/taxation">Taxation</Link>
        </div>
        <div className="footerSection">
          <h3>Registered Address</h3>
          <p>
            175 & 176, Bannerghatta Main Road, Dollars Colony, Bengaluru,
            Karnataka 560076
          </p>
        </div>
        <div className="footerSection">
          <h3>Legal Terms</h3>
          <a href="/privacy">Privacy Policy</a>
          <a href="/tandc">Terms and conditions</a>
        </div>
      </FooterDesign>
      <FooterBottom>
        <div>© Copyright 2020 VJLLP, All rights reserved</div>
        <div>CA LOGO</div>
      </FooterBottom>
    </>
  );
};
