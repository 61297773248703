import React from 'react';
import styled from 'styled-components';
import {
  accentcolor,
  secondaryColor,
  spGigantic,
  spLarge,
  spMedium,
  spSmall,
} from '../styleDefaults';

const TeamCardDesign = styled.div`
  background-color: ${accentcolor};
  border-radius: ${spMedium};
  padding: ${spLarge};
  box-shadow: 2px 2px 2px #2d2d2d;
  display: flex;
  flex-direction: column;
  width: 29%;
  height: 50%;
  position: relative;
  color: ${secondaryColor};

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: ${spGigantic};
  }

  p {
    font-size: 1.2rem;
  }

  .teamMemberName {
    margin-top: ${spSmall};
    margin-bottom: ${spSmall};
  }
`;

export const TeamCard = ({ firstName, lastName, title, photo }) => {
  return (
    <TeamCardDesign>
      <h2 className="teamMemberName">{firstName}</h2>
      <h1 className="teamMemberName">{lastName}</h1>
      <p>{title}</p>
    </TeamCardDesign>
  );
};
