import React from 'react';
import team_img from '../assets/team_bg_blue_1.png';
import styled from 'styled-components';
import { secondaryColor, spGigantic, spLarge } from '../styleDefaults';
import { TeamCard } from './teamCard';

const TeamWrap = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${team_img});
  background-size: cover;
  background-position: center;
  padding: ${spGigantic};
  display: grid;
  grid-template-rows: 20% 80%;

  #title {
    color: ${secondaryColor};
    text-align: center;
    text-decoration: underline;
    text-decoration-color: ${secondaryColor};
  }

  @media (max-width: 768px) {
    height: fit-content;
    grid-template-rows: 15% 85%;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  margin-top: ${spLarge};
  margin-bottom: ${spLarge};
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Team = () => {
  return (
    <TeamWrap>
      <h1 id="title">MEET OUR TEAM</h1>
      <CardsWrapper>
        <TeamCard
          firstName="Adyantha"
          lastName="Venkat"
          title="Managing Partner"
        />
        <TeamCard
          firstName="Pavan"
          lastName="Jayaprakash"
          title="Managing Partner"
        />
        <TeamCard firstName="FirstName" lastName="LastName" title="Position" />
      </CardsWrapper>
    </TeamWrap>
  );
};
