import './App.css';
import { Footer } from './components/footer';
import { Landing } from './components/landing';
import { Navbar } from './components/navbar';
import { Services } from './components/services';
import { Team } from './components/team';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AboutUs } from './components/aboutUs/AboutUs';
import { Careers } from './components/careers/Careers';
import { Contact } from './components/contactUs/Contact';
import { Service } from './components/services/Service';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route exact path="/">
          <Landing />
          <Services />
          <Team />
        </Route>
        <Route path="/about">
          <AboutUs />
        </Route>
        <Route path="/careers">
          <Careers />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/service">
          <Service />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
