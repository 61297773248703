import React, { useState } from 'react'
import styled from 'styled-components'
import {primaryColor, spLarge, spMedium} from '../../styleDefaults'
import data from "../../data/services.json"

const {advisory, auditing, taxation, laws} = data;

export const Service = () => {
  const [activeColor, setActiveColor] = useState(primaryColor);
  const [activeData, setActiveData] = useState(auditing);
  

  const changeDetails = (color, data) => {
    setActiveColor(color)
    setActiveData(data);
    window.location.href = "#data"
  }

     return (
      <>
        <ServiceWrapper>
          <ServiceCard 
            title={auditing.displayName}
            subTitle={auditing.oneLiner}
            color={primaryColor}
            data={auditing}
            clicked={changeDetails}
          />
          <ServiceCard 
            title={taxation.displayName}
            subTitle={taxation.oneLiner}
            color="red"
            data={taxation}
            clicked={changeDetails}
          />
          <ServiceCard 
            title={laws.displayName}
            subTitle={laws.oneLiner}
            color="brown"
            data={laws}
            clicked={changeDetails}
          />
          <ServiceCard 
            title={advisory.displayName} 
            subTitle={advisory.oneLiner}
            color="olive"
            data={advisory}
            clicked={changeDetails}
          />
        </ServiceWrapper>
        <SelectedService bg={activeColor}>
          <h1 className="center" id="data">{activeData.displayName}</h1>
          <p className="justify">{activeData.description}</p>
          <p className="center">Scroll to see more</p>
        </SelectedService>
        <ServiceCategories>
          {
            Object.keys(activeData.subCategories).map(sub => {
              return (
                <div key={sub}>
                  <h3>{sub}</h3>
                  <p>{activeData.subCategories[sub]}</p>
                </div>
              )
            })
          }
        </ServiceCategories>
      </>
    )
}

const ServiceCard = ({title, color, subTitle, clicked, data}) => {
  return (
    <ServiceCardStyle color={color} onClick={() => clicked(color, data)}>
      <div className="title">
        <h3>{title}</h3>
        <div className={`arrowCircle`}></div>
      </div>
      <p>{subTitle}</p>
    </ServiceCardStyle>
  )
}

const ServiceWrapper = styled.div`
  padding: ${spLarge};
  padding-top: 65px;
  display: flex;
  justify-content: space-around;

  @media (max-width: 600px){
    flex-direction: column;
  }
`;

const SelectedService = styled.div`
  padding: ${spMedium};
  color: white;
  background-color: ${props => props.bg};

  .center{
    text-align: center;
  }

  .justify{
    text-align: justify;
  }
`;

const ServiceCategories = styled.div`
  padding: ${spLarge};
`

const ServiceCardStyle = styled.div`
  width: 20vw;
  height: 15vw;
  display: flex;
  justify-content: space-between;
  padding: ${spLarge};
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 8px;

  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .arrowCircle{
    border-radius: 100px;
    height: 30px;
    width: 30px;
    background-color: ${props => props.color}
  }

  @media (max-width: 600px){
    width: 100%;
    height: 100%;
    margin-bottom: ${spLarge};

    .arrowCircle{
      height: 20px;
      width: 20px;
    }
  }
   
`;