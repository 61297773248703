import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { primaryColor, spGigantic, spLarge, spMedium, spSmall } from '../../styleDefaults';
import phoneLogo from "../../assets/contact_us_logos/smart_phone.png";
import emailLogo from "../../assets/contact_us_logos/email.png";
import { Link } from 'react-router-dom';
import { ParticleComponent } from '../particle';

export const Contact = () => {
    const [selectedOption, setSelectedOption] = useState("0")
    const [selectedLink, setSelectedLink] = useState("/contact")

    useEffect(() => {
        if(selectedOption === "4" ||selectedOption === "5"){
            document.getElementById("contactForm").style.display = "block";
            setSelectedLink("/contact")
            document.getElementById("submitButton").style.display = "none";
        }else{
            if (selectedOption=== "1") setSelectedLink("/service") 
            if (selectedOption=== "2") setSelectedLink("/careers") 
            if (selectedOption=== "3") setSelectedLink("/") 
            document.getElementById("contactForm").style.display = "none";
            document.getElementById("submitButton").style.display = "block";
        }
    }, [selectedOption])

    return (
        <MainWrapper>
            <ParticleComponent />
            <ContactWrapper>
            <div className="centerWrapper">
                <h1>Contact Us</h1>
                <div id="line"></div>
                <p>For all enquiries, please fill the form below</p>
                <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                    <option disabled value="0">Select</option>
                    <option value="1">I would like to enquire about the services offered</option>
                    <option value="2">I would like to work at VJLLP</option>
                    <option value="3">I would like to know more about the firm</option>
                    <option value="4">I would like to leave a personal message to the team</option>
                    <option value="5">Other</option>
                </select>
            </div>
            <LandingCardWrapper id="contactForm">
                <LandingCard>
                    <div id="lineOne">
                        <div>
                        <label htmlFor="name">Name *</label>
                        <input type="text" id="name" />
                        </div>
                        <div>
                        <label htmlFor="company">Company Name(optional)</label>
                        <input type="text" id="company" />
                        </div>
                    </div>
                    <label htmlFor="email">Email Address *</label>
                    <input type="text" id="email" />
                    <label htmlFor="phone">Phone Number *</label>
                    <input type="text" id="phone" />
                    <label htmlFor="phone">Message *</label>
                    <textarea rows="5" />
                    <CenterWrapper>
                        <Button>Send us a message</Button>
                    </CenterWrapper>
                </LandingCard>
            </LandingCardWrapper>
            <div id="submitButton">
                <Link to={selectedLink}>
                    <button disabled={selectedOption === "0"}>
                        {selectedOption !== "0" ? "Click me for the answer" : "Submit" }
                    </button>
                </Link>
            </div>
            <div id="or">-or-</div>
            <div className="contactBox">
                <div className="indBox">
                    <a href="tel:+918126138138">
                        <div className="imageWrapper">
                            <img src={phoneLogo} alt="Phone Logo" />
                        </div>
                        <h5>Text or Call us</h5>
                    </a>
                </div>
                <div className="indBox">
                    <a href="mailto:contact@vjllp.in">
                        <div className="imageWrapper">
                            <img src={emailLogo} alt="Email Logo" />
                        </div>
                        <h5>Drop us a email</h5>
                    </a>
                </div>
                <div className="indBox">
                    <h5>Visit our office</h5>
                    <div className="indBox3Lines">
                        <i className="fas fa-map-marker-alt"></i>
                        <p>175 & 176, Bannerghatta Main Road, Dollars Colony, Bengaluru, Karnataka 560076</p>    
                    </div>
                    <div className="indBox3Lines">
                        <i className="fas fa-address-book"></i>
                        <p>
                            +91 8126138138, 080 2121 7342
                            contact@vjllp.com
                        </p>    
                    </div>
                </div>
            </div>
            </ContactWrapper>
        
        </MainWrapper>
    )
}

const MainWrapper = styled.div`
    position: relative;
`;

const ContactWrapper = styled.div`
    padding-top: 45px;
    display: flex;
    align-items: center;
    flex-direction: column;

    #submitButton {
        margin-top:${spLarge};
        button{
            padding: ${spMedium};
            color: white;
            background-color:${primaryColor};
            cursor: pointer;
        }
        button:disabled{
            background-color:gray;
            cursor: not-allowed;
        }
    }

    .centerWrapper{
        width: 40%;
        display: flex;
        align-items: center;
        flex-direction: column;

        h1{
            color: ${primaryColor};
        }
        p{
            color: gray;
            font-weight: bold;
        }
        #line{
            width: 100%;
            border-top: 1px solid black;
        }
        select{
            padding: ${spMedium};
            width: 100%;
            background-color: #eeeeee;

            option{
                background-color: white;
            }
        }
    }

    #or{
        margin:${spGigantic} 0;
        font-weight: bold;
    }

    .contactBox{
        width: 80%;
        border: 1px solid ${primaryColor};
        display: flex;
        margin: ${spLarge} 0;

        .indBox{
            width: 33.33%;
            display: flex;
            align-items: center;
            flex-direction: column;
            color: ${primaryColor};
            padding: 0 1rem;

            .imageWrapper{
                width: 10vw;
                height: 10vw;
                display: flex;
                justify-content: center;

                img{
                    object-fit: cover;
                    max-width: 100%;
                }
            }

            a{
                text-decoration: none;
                color: ${primaryColor};
                text-align: center;
            }

            .indBox3Lines{
                display: grid;
                grid-template-columns: 8% 92%;
                width: 100%;   
                margin-bottom:${spLarge}; 

                p{
                    margin: 0;
                }
            }
        }

         .indBox:not(:last-child){
             border-right: 1px solid ${primaryColor};
         }
    }

    @media(max-width: 768px){
        .centerWrapper{
            width: 80%;
        }
        #contactForm{
            width: 80%;
        }
        .contactBox{
            width: 50%;
            flex-direction: column;
            .indBox{
                width: 100%;
                .imageWrapper{
                    height: 10vh;
                    width: 10vh;
                    margin: 0 auto;
                }
                .indBox3Lines{
                    grid-template-columns: 10% 90%;
                }
            }
            .indBox:not(:last-child){
                border-bottom: 1px solid ${primaryColor};
                border-right:0;
            }
        }
    }

    @media(max-width: 600px){
        .contactBox{
            width: 80%;
        }
    }

`;

const Button = styled.button`
  border: 1px solid black;
  box-shadow: 2px 2px gray;
  border-radius: 20px;
  width: fit-content;
  padding: ${spLarge};
  background-color: rgba(255, 255, 255, 0);
  margin-top: ${spGigantic};
`;

const CenterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LandingCardWrapper = styled.div`
  width: 40%;
  border: 1px solid black;
  border-radius: 8px;
  margin-top: ${spLarge};
`;

const LandingCard = styled.div`
  border-radius: ${spSmall};
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${spGigantic};
  opacity: 0.95;

  input,
  textarea {
    margin-bottom: ${spMedium};
    border: 0;
    border-bottom: 1px solid black;
    padding-top: ${spMedium};
    padding-bottom: ${spMedium};
    resize: none;
  }

  #lineOne {
    display: flex;
    width: 100% !important;
    justify-content: space-between;

    div {
      width: 49%;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    #lineOne {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
`;