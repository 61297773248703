import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { secondaryColor, spMedium, spSmall } from '../styleDefaults';

const NavigationBar = styled.nav`
  width: 100vw;
  padding: ${spMedium};
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  background-color: ${secondaryColor};
  border-bottom: 1px solid #999999;

  a {
    padding-right: ${spSmall};
    padding-left: ${spSmall};
    color: black;
    text-decoration: none;
  }
`;

export const Navbar = () => {
  return (
    <NavigationBar>
      <div>
        <Link to="/">Vjllp</Link>
      </div>
      <div>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact us</Link>
        <Link to="/careers">Careers</Link>
        <Link to="/service">Our Services</Link>
      </div>
    </NavigationBar>
  );
};
