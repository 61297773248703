import React from 'react'
import styled from 'styled-components'
import { primaryColor } from '../../styleDefaults'
import agriculture from "../../assets/industry_logos/agriculture.png"
import aviation from "../../assets/industry_logos/aviation.png"
import automobile from "../../assets/industry_logos/automobile.png"
import banking from "../../assets/industry_logos/banking.png"
import education from "../../assets/industry_logos/education.png"
import health from "../../assets/industry_logos/health.png"
import infrastructure from "../../assets/industry_logos/infrastructure.png"
import it from "../../assets/industry_logos/it.png"
import oil from "../../assets/industry_logos/oil.png"
import retail from "../../assets/industry_logos/retail.png"
import services from "../../assets/discover_more_logos/services_offered.png"
import jobs from "../../assets/discover_more_logos/jobs.png"
import blogs from "../../assets/discover_more_logos/blogs.png"
import { Link } from 'react-router-dom'



export const AboutUs = () => {
    return (
        <>
        <Header>
           <div id="rectangle">
                <div id="inner-rectangle">
                    <span><u>ABOUT US</u></span>
                </div>
            </div> 
            <div id="content">
                <h1>
                    AT VJLLP, WE LOREM
                    IPSUM DORET SIT AMET
                    ADIPSCING.
                </h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Eget felis eget. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit, sed do 
                    eiusmod tempor incididunt ut labore et
                    dolore magna aliqua. Eget felis eget 
                </p>
            </div>
        </Header>
        <Clientele>
            <h1>Our Clientele</h1>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
                Eget felis eget nunc lobortis 
                mattis. Elit ut aliquam purus sit amet. 
            </p>
            <div id="line1">
                <div className="extreme">
                    <img src={agriculture} alt="agriculture" />
                </div>
                <div className="mid">
                    <img src={infrastructure} alt="infrastructure" />
                </div>
                <div className="center">
                    <img src={aviation} alt="aviation" />
                </div>
                <div className="mid">
                    <img src={oil} alt="oil" />
                </div>
                <div className="extreme">
                    <img src={education} alt="education" />
                </div>
            </div>
            <div id="line2">
                <div className="extreme">
                    <img src={health} alt="health" />
                </div>
                <div className="mid">
                    <img src={it} alt="it" />
                </div>
                <div className="center">
                    <img src={retail} alt="retail" />
                </div>
                <div className="mid">
                    <img src={automobile} alt="automobile" />
                </div>
                <div className="extreme">
                    <img src={banking} alt="banking" />
                </div>
            </div>
        </Clientele>
        <DiscoverMore>
            <h1>Discover More</h1>
            <div>
            <DiscoverCard 
                cardTitle="Services Offered" 
                cardDescription="Check out the wide ranges of services offered by us." 
                cardImage={services} 
                cardDestination="/service"
            />
            <DiscoverCard 
                cardTitle="Latest News" 
                cardDescription="Find the latest blogs, news, articles here." 
                cardImage={blogs} 
                cardDestination="/blog"
            />
            <DiscoverCard 
                cardTitle="Join Our Team" 
                cardDescription="Check out open vacancies and apply for jobs here." 
                cardImage={jobs} 
                cardDestination="/careers"
            />
            </div>
        </DiscoverMore>
        </>
    )
}

const DiscoverCard = ({cardTitle, cardImage, cardDescription, cardDestination}) => {
    return (
        <DiscoverCardStyle>
            <Link to={cardDestination}>
                <h2>{cardTitle}</h2>
                <div className="img">
                    <img src={cardImage} alt={cardTitle} />
                </div>
                <h4>{cardDescription}</h4>
            </Link>
        </DiscoverCardStyle>
    )
}

const Header = styled.div`
    padding: 2rem;
    padding-top: 4rem;
    display: grid;
    grid-template-columns: 50% 50%;
    height: calc(100vh - 44.2px);

    #content{
        h1 {
            font-size: 3.5rem;
        }

        p{
            font-size: 1.75rem;
        }
    }

    #rectangle {
        position: relative;
        height: 70vh;
        width: 35vw;
        border: 8px solid ${primaryColor};
        display: flex;
        justify-content: center;
    }

    #inner-rectangle{
        position: absolute;
        height: inherit;
        width: inherit;
        top: 1rem;
        left: 1rem;
        border: 8px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 5rem;
        font-weight: bold;
    }


@media (max-width: 1035px){
    grid-template-columns: 45% 55%;

    #rectangle{
        width: 38vw;
    }

    #inner-rectangle{
       font-size: 4rem;
    }
}
    @media (max-width: 900px){
        display: flex;
        flex-direction: column;
        padding: 1rem;
        padding-top: 5rem;
        height: 100%;

        #rectangle {
            height: 35vh;
            width: 85vw;
        }
        #inner-rectangle{
            font-size: 4rem;
        }

        #content{ 
            position: relative;
            top: 1.5rem;
            padding: 2rem 0;
            h1{
                font-size: 1.4rem;
            }
            p{
                font-size: 0.7rem;
            }
        }
    }
`

const Clientele = styled.div`
    background-color: ${primaryColor};
    padding: 4rem;
    color: white;

    h1{
        text-align: center;
        text-decoration: underline;
        font-weight: bold;
    }

    p{
        font-size: 1.4rem;
    }

    #line1, #line2{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2rem 0;

        img{
            max-width: 100%;
            object-fit: cover;
            border: 4px solid white;
            border-radius: 8px;
        }
    }

    .center{
        height: 18vw;
        width: 18vw;
    }

    .mid{
        height: 13vw;
        width: 13vw;
    }

    .extreme{
        height: 8vw;
        width: 8vw;
    }

    
    @media (max-width: 768px){
        padding: 2rem;

        p{
            font-size: 1rem;
        }
    }
`

const DiscoverMore = styled.div`
    padding: 3rem;
    h1{
        text-align: center;
        text-decoration: underline;
        margin-bottom: 5rem;
    }

    div{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    @media (max-width: 768px){
        div{
            flex-direction: column;
        }
    }
`;
const DiscoverCardStyle = styled.div`
    border: 2px solid ${primaryColor};
    box-shadow: 4px 4px 8px gray;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 23%;


    .img{
        height: 18vh;
        width: 50%;

        img{
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
        }
    }

    a{
        color: black;
        text-decoration: none;
    }

    @media (max-width: 1100px){
        width: 50%;
        margin-bottom: 1.5rem;
        align-items: center;
    }

    @media (max-width: 768px){
        width: 100%;
    }
`
