import styled from 'styled-components';
import { accentcolor, lightBlueColor, primaryColor } from '../styleDefaults';

export const PrimaryText = styled.span`
  color: ${primaryColor};
`;

export const AccentText = styled.span`
  color: ${accentcolor};
`;

export const LightBlueText = styled.span`
  color: ${lightBlueColor};
`;

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
